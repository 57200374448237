import {
  PointsContainer,
  PointsLabel,
} from '@/components/user-profile/reward-points/components';
import RewardPointsProgressSlider from '@/components/user-profile/reward-points/RewardPointsProgressSlider';
import useAppSelector from '@/hooks/useAppSelector';
import { myRewardPointsSelector } from '@/redux/selectors/activitySelector';
import { adminUserRewardPointsSelector } from '@/redux/selectors/users';
import { Box } from '@mui/material';
import React from 'react';

const MonthlyPointsActivity: React.FC<{ userId?: number }> = ({ userId }) => {
  const rewardPoints = useAppSelector(myRewardPointsSelector);
  const adminUserRewardPoints = useAppSelector(adminUserRewardPointsSelector);

  if (!rewardPoints || adminUserRewardPoints) {
    return null;
  }
  return (
    <PointsContainer>
      <PointsLabel variant={'h2'} mb={4}>
        Monthly Points Earned
      </PointsLabel>
      <RewardPointsProgressSlider userId={userId} />
      <Box>
        {`${Number(rewardPoints?.earnedPoints).toLocaleString('en')} / ${
          rewardPoints?.availablePoints
            ? Number(rewardPoints?.availablePoints).toLocaleString('en')
            : 0
        }`}
      </Box>
    </PointsContainer>
  );
};

export default MonthlyPointsActivity;
